import {bindable, customElement, inject} from 'aurelia-framework';
import {FormServiceFactory} from '../form/service/form-service-factory';
import {currencies} from "../currency/model/currencies.model";
import {UserClient} from "../api/user-client";
import 'lodash.product';
import * as _ from "lodash";
import moment from 'moment-timezone';
import {Confirm} from "../dialog/confirm";
import {DialogService} from "aurelia-dialog";
import {EditDimensionsDialog} from "./edit-dimensions-dialog";
import Sortable from "sortablejs";
import Client from "../api/client";
import {PriceSetInputNew} from "./price-set-input-new";
import {DimensionConfig} from "./dimension-config";

@customElement('stop-sale-set')
@inject(FormServiceFactory, UserClient, DialogService, Client, DimensionConfig)
export class StopSaleSet {

    lines = [];
    specialLines = [];
    specialDimensions = [];
    @bindable sorterValue;

    constructor(formServiceFactory, user, dialogService, client, dimensionConfig) {
        this.formServiceFactory = formServiceFactory;
        this.dialogService = dialogService;
        this.client = client;
        this.dimensionConfig = dimensionConfig;

        const settings = user.getUser()?.organization?.settings?.priceSettings;

        this.predefinedSeasons = settings.predefinedSeasonTexts ?? [];
        this.sorterValue = false;

    }

    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    _getControlUID() {
        return 'stop-sale-set-input-' + this.field.property;
    }

    async bind() {
        let formValue = this.field.formService.getValue();
        this.context = formValue.context ?? this.field.formService.config.contextObjectRef;

        if (this.context.modelId === 'price/contingent-pool') {
            this.context = (await this.client.get('price/contingent-pool/' + this.context.id)).context;
        }

        this.field.setValue(this._processModelValue());
    }

    attached()
    {
        this.initializeSorting();
    }

    initializeSorting()
    {
        let element = document.getElementById(this._getControlUID());

        if (!element) {
            return;
        }

        this.sortable = Sortable.create(element, {
            handle: '.fa-bars',
            forceFallback: true,
            onEnd: this.onEnd.bind(this)
        });
    }

    onEnd(evt)
    {
        this.specialLines.move(evt.oldIndex, evt.newIndex);

        this.updateAndSaveValue();
    }

    valueChanged(newValue) {

        //Important check here, as a view change also triggers this callback and this way we would have a loop
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._processModelValue();
    }

    _processModelValue() {
        let lines = [];
        let specialDimensions = {
            date: 1
        };
        const dimensions = {};
        let specialLines = [];

        for (let i = 0; i < this.value?.lines.length; i++) {

            const line = _.clone(this.value.lines[i]);

            for (const dimensionKey of this.dimensionConfig.keys()) {

                const dimensionConfig = this.dimensionConfig.get(dimensionKey);

                if (dimensionConfig.hasDimension(line, this.value.lines)) {
                    if (line.special) {
                        specialDimensions[dimensionKey] = 1;
                    } else {
                        if (!dimensions[dimensionKey]) {
                            dimensions[dimensionKey] = [];
                        }

                        let [identifier, object] = dimensionConfig.getDimension(line);

                        dimensions[dimensionKey][identifier] = object;
                    }
                }
            }

            if (line.special) {
                specialLines.push(line);
            } else {
                lines.push(line);
            }
        }

        this.specialDimensions = Object.keys(specialDimensions);
        let dimensionValues = {};

        for (let dimensionKey in dimensions) {
            dimensionValues[dimensionKey] = Object.values(dimensions[dimensionKey]);
        }

        this.dimensions = dimensionValues;
        this.lines = lines;
        this.specialLines = specialLines;

        console.log('SPECIAL LINES', this.specialLines);

        return this._calculateValue();
    }

    _calculateValue() {

        let dimensions = [];
        this.dimensionTypes = [];

        for (let dimensionKey of this.dimensionConfig.keys()) {
            let dimensionData = this.dimensions?.[dimensionKey];
            let dimensionConfig = this.dimensionConfig.get(dimensionKey);

            if (!dimensionData) {
                continue;
            }

            dimensionData = dimensionData.filter(e => dimensionConfig.hasDimension(e));

            if (dimensionData.length > 0) {
                if (dimensionConfig.postProcess) {
                    dimensionData = dimensionConfig.postProcess(dimensionData);
                }

                dimensions.push(dimensionData);
                this.dimensionTypes.push({type: dimensionKey, label: dimensionConfig.label});
            }
        }

        let prices = {};

        for (let i = 0; i < this.lines.length; i++) {

            const line = this.lines[i];

            //We reuse old key here, if dimension changes, values are kept
            prices[this.dimensionConfig.getKey(this.dimensionTypes, dimensions, line)] = line;

            if (line.key && !prices[line.key]) {
                prices[line.key] = line;
            }
        }

        let product = _.product(...dimensions);
        let lines = [];

        for (let item of product) {

            let labels = [];
            let objects = [];

            for (let i = 0; i < item.length; i++) {

                let dimensionValue = item[i];
                let dimensionConfig = this.dimensionConfig.get(this.dimensionTypes[i].type);

                labels.push(dimensionConfig.valueLabel(dimensionValue));
                objects.push(_.pick(dimensionValue, dimensionConfig.pick));
            }

            let line = Object.assign({}, ...objects);
            let lineKey = this.dimensionConfig.getKey(this.dimensionTypes, dimensions, line);

            line.key = lineKey;
            line.labels = labels;

            lines.push(line);
        }

        this.lines = lines;

        return this.updateModelValue();
    }

    addSpecialLine()
    {
        this.specialLines.push({});

        this.updateAndSaveValue();
    }

    duplicateSpecialLine(index)
    {
        this.specialLines.push(_.cloneDeep(this.specialLines[index]));
    }

    removeSpecialLine(index)
    {
        this.specialLines.splice(index, 1);

        this.updateAndSaveValue();
    }

    getFields(dimensions)
    {
        let fields = [];

        for (let dimensionKey of dimensions) {
            fields.push(...this.dimensionConfig.get(dimensionKey).pick);
        }

        return fields;
    }

    updateAndSaveValue() {
        this.viewChange = true;

        this.value = this.updateModelValue();
    }

    tableSort = () => {
        if(this.sorterValue){
          this.lines.sort((a, b) => new Date(b?.dates[0].from)  - new Date(a?.dates[0].from) );
        }else{
          this.lines.sort((a, b) => new Date(a?.dates[0].from)  - new Date(b?.dates[0].from) );
        }
        this.sorterValue = !this.sorterValue;
     };

    updateModelValue() {

        let fields = this.getFields(Object.keys(this.dimensions));

        let priceLines = _.map(this.lines, item => {
            return _.pick(item, fields);
        });

        let specialFields = this.getFields(this.specialDimensions);

        let specialPriceLines = _.map(this.specialLines, item => {

            const line = _.pick(item, specialFields);

            line.special = true;

            return line;
        });

        let cloneLines = [];

        for (let specialPriceLine of specialPriceLines) {
            cloneLines.push(specialPriceLine);
        }

        for (let priceLine of priceLines) {

            if (!priceLine.dates) {
                cloneLines.push(priceLine);
                continue;
            }

            let dates = priceLine.dates;
            delete priceLine.dates;

            for (let date of dates) {
                cloneLines.push(Object.assign({}, priceLine, date));
            }
        }

        return {
            lines: cloneLines,
        };
    }

    editDimensions() {
        this.dialogService.open({
            viewModel: EditDimensionsDialog,
            model: {
                context: this.context,
                predefinedSeasons: this.predefinedSeasons,
                dimensions: this.dimensions ?? {},
                contingent: true
            }
        }).whenClosed(response => {
            if (response.wasCancelled) {
                return;
            }

            this.viewChange = true;
            this.dimensions = response.output.dimensions;
            this.value = this._calculateValue();
        });
    }
}
